.header__404 {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fon {
  display: none;
}
.content-404 {
  padding-top: 50px;
  background-color: var(--bg);
}

.block-404 {
  padding-top: 0px;
   background-image: url('../img/men.svg');
   background-repeat: no-repeat;
   background-size: contain;
   background-position: center;
   height: 100%;
}

.block-404-content {
  max-width: 400px;
  margin-left: auto;
}

.block-404-number {
  position: relative;
  font-family: 'RobotoSlab-Thin';
  font-weight: 100;
  font-size: 240px;
  line-height: 260px;
  color:#65CD7B;
}

.block-404-text1, .block-404-text2{
  font-family: 'EtelkaPro-Light';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #CBCADA;
  margin: 0;
}
.block-404-text2 {
  color: #605E75;
  padding-top: 20px;
}

.button404 {
  margin-top: 40px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-img {
  position: absolute;
  top: 64px;
  right: 23px;
}

@media (max-width: 768px) {
  .block-404-number {
    font-size: 184px;
    line-height: 196px;
  }
  .block-404-text1, .block-404-text2{
    font-size: 16px;
    line-height: 22px;
  }
  .block-404-content {
    max-width: 324px;
  }
  .content-404 {
    padding-top: 0px;
  }
  .button404 {
  margin-top: 40px;
  }
  .block-404 {
    padding-top: 0px;
  }
}



@media (max-width: 576px) {
  .block-404-text1, .block-404-text2 {
    padding-top: 10px;
  }
  .block-404-number {
    font-size: 100px;
    line-height: 100px;
    text-align: center;
    max-width: 210px;
    margin: 0 auto;
  }
  .error-img {
    top: 30px;
    right: -10px;
  }
  .block-404-content {
    max-width: 100%;
    margin-left: 0;
    margin: 0 auto;
  }
  .block-404-number {
    padding-top: 20px;
      text-align: center;
  }
  .fon {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 300px;
    margin: 0 auto;
  }
  .block-404 {
    background-image: none;
  }
  .block-404-text1, .block-404-text2 {
      font-size: 14px;
      line-height: 20px;
  }
  .button404 {
      margin-top: 40px;
  }
}
