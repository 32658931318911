// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "EtelkaPro-Light", sans-serif;
  --content-width: 1200px;
  --container-offset: 30px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --bodi1: #434253;
  --bodi2: #605E75;
  --bodi3: #CBCADA;
  --gradiend: linear-gradient(46.76deg, #7DCB75 9%, #65CD7B 42.58%, #65CF78 84.18%, #B9EAB2 137.85%);
  --gradiend2: linear-gradient(101.58deg, #2F2E39 -4.33%, #3B3949 35.11%, #14131E 98.03%);
  --bg: #2F2D3C;
  --gr2: linear-gradient(98.92deg, #2F2E39 -29.2%, #373645 35.21%, #2F2E3D 65.04%, #14131E 138%);
  --error: #A45D5D;
}

@media (max-width: 578px)  {
  :root {
  // base
  --container-offset: 15px;
  }
}
