.send {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--gradiend);
		&__title {
      margin: 0;
      font-family: 'RobotoSlab-Thin';
      font-weight: 100px;
      font-size: 60px;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
		}

    &__email, &__tel, &__name, &__comment {
        font-family: 'EtelkaPro-Light';
        font-weight: 300;
        font-size: 20px;
        line-height: 20px;
        background-color: transparent;
        height: 40px;
        margin-bottom: 10px;
        color: #FFFFFF;
        padding: 0 10px 0 0;
        border: none;
        border-bottom: 1px solid #605E75;
        outline: none;
        width: 100%;
    }
    &__email, &__tel, &__comment {
        margin-top: 10px;
    }
    &__button-group {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
    }
    &__checkbox {
        display: flex;
        align-items: center;
    }
    &__checkbox-text {
        font-family: 'EtelkaPro-Light';
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        color: #FFFFFF;
        margin: 0;
        max-width: 368px;
        margin-left: 16px;
    }
}

form {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

// ЧЕКБОХ
label {
  position: relative;
}

input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type="checkbox"] + label::before {
  content: '';
  display: inline-block;
  margin: .2em .5em;
  width: .8em;
  height: .8em;
  line-height: .75em;
  transition: all .5s;
  border: 1px solid #f20000;
}


input[type="checkbox"]:checked + label::before {
  content: '\2713';
  // background: linear-gradient(237.24deg, #7FC278 -82.09%, #6CA678 -40.01%, #77C584 12.14%, #AAEEA0 79.41%);
  border: 1px solid #fff;
  // box-shadow: inset 0 0 0 1px white, 0 0 0 1px #65CD7B;
  color: #2F2D3C;
}


// ФАЙЛ

.file-upload
{
	display:block;
	text-align:center;
  padding-top: 20px;
}

.file-upload .file-select
{
	display:block;
	color:#34495e;
	cursor:pointer;
	height:60px;
	line-height:40px;
	overflow:hidden;
	position:relative;
  background: linear-gradient(237.24deg, #7FC278 -82.09%, #6CA678 -40.01%, #77C584 12.14%, #AAEEA0 79.41%);
  border: 1px dashed #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload .file-select .file-select-button
{
	padding:0 10px;
	display:inline-block;
	height:80px;
	line-height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload .file-select .file-select-name
{
	line-height:40px;
	display:inline-block;
	padding:0 10px;
  display: flex;
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #2F2D3C;
}

.file-upload .file-select:hover
{
	border-color:#34495e;
	transition:all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button
{
	color:#FFF;
	transition:all .2s ease-in-out;
}

.file-upload.active .file-select
{
	transition:all .2s ease-in-out;
}

.file-upload.active .file-select .file-select-button
{
	color:#FFF;
	transition:all .2s ease-in-out;
}

.file-upload .file-select input[type=file]
{
	z-index:100;
	cursor:pointer;
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left:0;
	opacity:0;
	filter:alpha(opacity=0);
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}

.file-upload .file-select.file-select-disabled
{
	opacity:.65;
}

.file-upload .file-select.file-select-disabled:hover
{
	cursor:default;
	display:block;
	border:2px solid #dce4ec;
	color:#34495e;
	cursor:pointer;
	height:40px;
	line-height:40px;
	margin-top:5px;
	text-align:left;
	background:#FFF;
	overflow:hidden;
	position:relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button
{
	background:#dce4ec;
	color:#666;
	padding:0 10px;
	display:inline-block;
	height:40px;
	line-height:40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name
{
	line-height:40px;
	display:inline-block;
	padding:0 10px;
}

.file-upload2
{
  padding-top: 20px;
	display:block;
	text-align:center;
}

.file-upload2 .file-select
{
	display:block;
	color:#34495e;
	cursor:pointer;
	height:60px;
	line-height:40px;
	overflow:hidden;
	position:relative;
  background: linear-gradient(237.24deg, #7FC278 -82.09%, #6CA678 -40.01%, #77C584 12.14%, #AAEEA0 79.41%);
  border: 1px dashed #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload2 .file-select .file-select-button
{
	padding:0 10px;
	display:inline-block;
	height:80px;
	line-height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload2 .file-select .file-select-name
{
	line-height:40px;
	display:inline-block;
	padding:0 10px;
  display: flex;
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #2F2D3C;
}

.file-upload2 .file-select:hover
{
	border-color:#34495e;
	transition:all .2s ease-in-out;
}

.file-upload2 .file-select:hover .file-select-button
{
	color:#FFF;
	transition:all .2s ease-in-out;
}

.file-upload2.active .file-select
{
	transition:all .2s ease-in-out;
}

.file-upload2.active .file-select .file-select-button
{
	color:#FFF;
	transition:all .2s ease-in-out;
}

.file-upload2 .file-select input[type=file]
{
	z-index:100;
	cursor:pointer;
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left:0;
	opacity:0;
	filter:alpha(opacity=0);
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload2 .file-select.file-select-disabled
{
	opacity:.65;
}

.file-upload2 .file-select.file-select-disabled:hover
{
	cursor:default;
	display:block;
	border:2px solid #dce4ec;
	color:#34495e;
	cursor:pointer;
	height:40px;
	line-height:40px;
	margin-top:5px;
	text-align:left;
	background:#FFF;
	overflow:hidden;
	position:relative;
}

.file-upload2 .file-select.file-select-disabled:hover .file-select-button
{
	background:#dce4ec;
	color:#666;
	padding:0 10px;
	display:inline-block;
	height:40px;
	line-height:40px;
}

.file-upload2 .file-select.file-select-disabled:hover .file-select-name
{
	line-height:40px;
	display:inline-block;
	padding:0 10px;
}


@media (max-width: 998px) {
  .send {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .send__title {
    font-size: 50px;
    line-height: 50px;
  }
  .send__email, .send__tel, .send__name, .send__comment {
    font-size: 20px;
}
}
@media (max-width: 768px) {
  .send__title {
    font-size: 45px;
    line-height: 45px;
  }
  .send__email, .send__tel, .send__comment {
    margin-top: 10px;
  }
  form {
    padding-top: 40px;
  }
  .file-upload {
    padding-top: 20px;
  }

}

@media (max-width: 692px) {
  .send__title {
      font-size: 35px;
      line-height: 35px;
  }
  .send__email, .send__tel, .send__name, .send__comment {
  font-size: 18px;
  }
}

@media (max-width: 578px) {
.send__checkbox-text {
    font-size: 18px;
    line-height: 18px;
}
.send {
    padding-top: 50px;
    padding-bottom: 50px;
}
  .btn-platimir-send {
    width: 140px;
    margin-left: 2px;
  }
  .send__email, .send__tel, .send__name, .send__comment {
    height: 40px;
  }
}
@media (max-width: 510px) {
  .send__title {
      font-size: 30px;
      line-height: 30px;
  }
}

@media (max-width: 422px) {
  .send__title {
      font-size: 26px;
      line-height: 32px;
  }

  .file-upload .file-select input[type=file] {
      font-size: 16px;
      line-height: 22px;
  }
  .send__checkbox-text {
    font-size: 12px;
    line-height: 14px;
  }
}
