.footer {
  background-color: var(--bg);
	&__top {
    padding-top: 114px;
    display: flex;
    justify-content: space-between;
	}

	&__left {
    display: flex;
    align-items: center;
	}

	&__logo {
    margin-right: 40px;
	}

	&__logo-img {
	}

	&__tg {
    display: flex;
    align-items: center;
	}

	&__tg-img {
    margin-right: 12px;
	}

	&__tg-text {
    font-family: 'EtelkaPro-Bold';
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: var(--bodi2);
    transition: color .3s ease-in-out;
		}
    &__tg-text:hover {
      color:#65CD7B;
    }

	&__bottom {
     padding-top: 50px;
     padding-bottom: 50px;
     display: flex;
     align-items: start;
     justify-content: space-between;
		}

	&__bottom-item p{
    font-family: 'EtelkaPro-Light';
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: var(--bodi2);
    max-width: 400px;
    margin: 0;
	}

  &__bottom-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

	&__tg-text-p {
    font-family: 'EtelkaPro-Bold';
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: var(--bodi2);
    padding-bottom: 20px;
    transition: color .3s ease-in-out;
    text-align: right;
		}
    &__tg-text-p:hover {
      color:#65CD7B;
    }

	&__copyright {
    font-family: 'EtelkaPro-Light';
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: var(--bodi1);
    padding-bottom: 50px;
		}

	&__webdev {
    display: flex;
    flex-direction: column;
		}

	&__webdev a{
    font-family: 'EtelkaPro-Light';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--bodi1);
    padding-bottom: 12px;
    transition: color .3s ease-in-out;
		}
    &__webdev a:hover {
      color:#65CD7B;
    }
}

@media (max-width: 768px) {
  .footer__logo {
    margin-right: 0px;
  }
  .footer__left {
    flex-direction: column;
}
}

@media (max-width: 696px) {
  .footer__bottom-item p {
      font-size: 20px;
      max-width: 280px;
  }
}

@media (max-width: 578px) {
  .footer__top {
      padding-top: 114px;
  }
  .footer__bottom-item p {
    font-size: 16px;
    max-width: 224px;
}
}

@media (max-width: 524px) {
  .footer__tg {
  padding-top: 10px;
}
  .footer__top {
      flex-direction: column;
  }
  .footer__left {
    // flex-direction: row;
    // justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .footer__bottom {
    flex-direction: column;
  }
  .footer__bottom-item p {
    max-width: 100%;
  }
  .footer__bottom-right {
    padding-top: 40px;
    width: 100%;
    align-items: flex-start;
    position: relative;
  }
  .footer__webdev {
    position: absolute;
    top: 44px;
    right: 0;
}
  .footer__bottom {
      padding-bottom: 30px;
  }

}
@media (max-width: 400px) {
  .footer__tg-text-p {
      max-width: 100px;
  }
}

@media (max-width: 422px) {
  .footer__top {
      padding-top: 80px;
  }
 .footer__tg-text {
    font-size: 12px;
    line-height: 16px;
  }
  .footer__tg-img {
    margin-left: 5px;
  }
  .footer__tg {
    flex-direction: row-reverse;
  }
  .footer__logo {
    margin-right: 0px;
  }
  .footer__logo-img {
    width: 124px;
  }
  .footer__tg-img {
    margin-right: 0px;
  }
}
