.wedo {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--gr2);
	&__title {
    margin: 0;
    font-family: 'RobotoSlab-Thin';
    font-weight: 100;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    color: var(--bodi2);
	}

	&__content {
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: start;
	}

	&__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 33%;
	}

	&__img-wrapper {
    height: 149px;
	}

	&__img {
	}

	&__text {
    padding-top: 43px;
    font-family: 'EtelkaPro-Light';
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: var(--bodi3);
    margin: 0;
    padding-bottom: 60px;
	}
}

.btn-wedo {
  background-color: transparent;
}


@media (max-width: 998px) {
  .wedo__text {
    font-size: 18px;
  }
  .wedo__title {
    font-size: 50px;
    line-height: 50px;
  }
  .wedo {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (max-width: 840px) {
  .wedo__text {
    font-size: 16px;
  }
    .wedo__img-wrapper {
    height: 144px;
    width: 200px;
    display: flex;
  }
  .wedo__img {
    object-fit: contain;
  }
}

@media (max-width: 768px) {
  .wedo__title {
    font-size: 45px;
    line-height: 45px;
  }

}

@media (max-width: 692px) {
  .wedo__title {
      font-size: 35px;
      line-height: 35px;
  }
}

@media (max-width: 676px) {
  .wedo__content {
  padding-top: 40px;
  }
  .wedo__content {
      flex-direction: column;
  }
  .wedo__item {
      width: 100%;
      padding-bottom: 100px;
    }
  .wedo__title {
    color: #fff;
  }
  .wedo__text {
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .wedo__text {
    max-width: 400px;
  }
  .wedo__text {
  line-height: 20px;
  }
}

@media (max-width: 578px) {
  .wedo {
      padding-top: 50px;
      padding-bottom: 50px;
  }
}

@media (max-width: 422px) {
  .wedo__title {
      font-size: 26px;
      line-height: 32px;
  }
}
