@charset "UTF-8";
:root {
  --font-family: "EtelkaPro-Light", sans-serif;
  --content-width: 1200px;
  --container-offset: 30px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --bodi1: #434253;
  --bodi2: #605E75;
  --bodi3: #CBCADA;
  --gradiend: linear-gradient(46.76deg, #7DCB75 9%, #65CD7B 42.58%, #65CF78 84.18%, #B9EAB2 137.85%);
  --gradiend2: linear-gradient(101.58deg, #2F2E39 -4.33%, #3B3949 35.11%, #14131E 98.03%);
  --bg: #2F2D3C;
  --gr2: linear-gradient(98.92deg, #2F2E39 -29.2%, #373645 35.21%, #2F2E3D 65.04%, #14131E 138%);
  --error: #A45D5D;
}

@media (max-width: 578px) {
  :root {
    --container-offset: 15px;
  }
}
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
@font-face {
  font-family: "RobotoSlab-Thin";
  src: url("../fonts/../fonts/RobotoSlab-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "EtelkaPro-Thin";
  src: url("../fonts/../fonts/EtelkaPro-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "EtelkaPro-Light";
  src: url("../fonts/../fonts/EtelkaPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "EtelkaPro-Bold";
  src: url("../fonts/../fonts/EtelkaPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
  /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn-platimir {
  width: 200px;
  height: 48px;
  border-radius: 2px;
  font-family: "EtelkaPro-Bold";
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

.btn-dark {
  background-color: var(--bg);
  color: #65CD7B;
  border: 1px solid;
  border-color: #605E75;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
}

.btn-dark:hover {
  border-color: #65CD7B;
}

.btn-dark--header {
  margin-right: 16px;
}

.btn-green {
  background: var(--gradiend);
  color: #2F2D3C;
  border: 1px solid;
  border-color: #65CD7B;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
}

.btn-green:hover {
  border-color: #ffffff;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.6);
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(37, 10, 10, 0.6);
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.6);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.6);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.6);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(255, 255, 255, 0.6);
}

.header {
  background-color: var(--bg);
}
.header__top {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.header__left {
  display: flex;
  align-items: center;
}
.header__logo {
  margin-right: 40px;
}
.header__tg {
  display: flex;
  align-items: center;
}
.header__tg-img {
  margin-right: 12px;
}
.header__tg-text {
  font-family: "EtelkaPro-Bold";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: var(--bodi2);
  transition: color 0.3s ease-in-out;
}
.header__tg-text:hover {
  color: #65CD7B;
}
.header__title {
  padding-top: 80px;
  font-family: "RobotoSlab-Thin";
  font-weight: 100;
  font-size: 90px;
  line-height: 90px;
  text-align: center;
  text-transform: uppercase;
  color: var(--bodi2);
  margin: 0;
}
.header__green {
  color: #65CD7B;
}
.header__subtitle {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--bodi3);
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px;
}
.header__hero-img {
  padding-top: 40px;
}
.header__hero-wrapper {
  position: relative;
}
.header__hero-img-gradient {
  position: absolute;
  background: linear-gradient(180deg, rgba(47, 45, 60, 0) 0%, #2F2D3C 100%);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 200px;
}

@media (max-width: 1240px) {
  .header__title {
    font-size: 80px;
    line-height: 80px;
  }
}
@media (max-width: 998px) {
  .header__title {
    font-size: 70px;
    line-height: 70px;
  }
}
@media (max-width: 956px) {
  .header__left {
    flex-direction: column;
  }

  .header__logo {
    padding-bottom: 20px;
  }

  .header__right {
    display: flex;
    flex-direction: column;
  }

  .btn-dark--header {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .header__title {
    font-size: 65px;
    line-height: 65px;
  }
}
@media (max-width: 694px) {
  .header__title {
    font-size: 60px;
    line-height: 60px;
  }

  .header__subtitle {
    font-size: 18px;
  }
}
@media (max-width: 614px) {
  .header__title {
    font-size: 52px;
    line-height: 50px;
  }

  .header__subtitle {
    font-size: 18px;
  }
}
@media (max-width: 578px) {
  .header__top {
    flex-direction: column;
  }

  .header__left {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header__logo {
    padding-bottom: 0px;
  }

  .header__right {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
  }

  .header__title {
    padding-top: 100px;
  }

  .header__subtitle {
    padding-bottom: 80px;
  }
}
@media (max-width: 510px) {
  .header__title {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (max-width: 486px) {
  .header__title {
    font-size: 45px;
    line-height: 45px;
  }

  .header__subtitle {
    font-size: 15px;
  }
}
@media (max-width: 482px) {
  .header__tg-text {
    font-size: 12px;
    line-height: 16px;
  }

  .header__tg-img {
    margin-left: 5px;
  }

  .header__tg {
    flex-direction: row-reverse;
  }

  .header__logo {
    margin-right: 0px;
  }

  .header__logo-img {
    width: 124px;
  }

  .header__tg-img {
    margin-right: 0px;
  }
}
@media (max-width: 440px) {
  .header__title {
    font-size: 42px;
    line-height: 42px;
  }
}
@media (max-width: 422px) {
  .header__title {
    font-size: 40px;
    line-height: 40px;
  }

  .btn-platimir {
    height: 40px;
    font-size: 10px;
    line-height: 12px;
  }
}
@media (max-width: 400px) {
  .header__title {
    font-size: 38px;
    line-height: 38px;
  }
}
@media (max-width: 380px) {
  .header__title {
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 360px) {
  .header__title {
    font-size: 34px;
    line-height: 34px;
  }

  .header__tg-img {
    width: 20px;
  }
}
@media (max-width: 360px) {
  .header__title {
    font-size: 33px;
    line-height: 33px;
  }

  .header__subtitle {
    font-size: 14px;
    line-height: 17px;
  }
}
@media (max-width: 332px) {
  .header__title {
    font-size: 32px;
    line-height: 32px;
  }
}
.wedo {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--gr2);
}
.wedo__title {
  margin: 0;
  font-family: "RobotoSlab-Thin";
  font-weight: 100;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: var(--bodi2);
}
.wedo__content {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.wedo__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33%;
}
.wedo__img-wrapper {
  height: 149px;
}
.wedo__text {
  padding-top: 43px;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: var(--bodi3);
  margin: 0;
  padding-bottom: 60px;
}

.btn-wedo {
  background-color: transparent;
}

@media (max-width: 998px) {
  .wedo__text {
    font-size: 18px;
  }

  .wedo__title {
    font-size: 50px;
    line-height: 50px;
  }

  .wedo {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 840px) {
  .wedo__text {
    font-size: 16px;
  }

  .wedo__img-wrapper {
    height: 144px;
    width: 200px;
    display: flex;
  }

  .wedo__img {
    object-fit: contain;
  }
}
@media (max-width: 768px) {
  .wedo__title {
    font-size: 45px;
    line-height: 45px;
  }
}
@media (max-width: 692px) {
  .wedo__title {
    font-size: 35px;
    line-height: 35px;
  }
}
@media (max-width: 676px) {
  .wedo__content {
    padding-top: 40px;
  }

  .wedo__content {
    flex-direction: column;
  }

  .wedo__item {
    width: 100%;
    padding-bottom: 100px;
  }

  .wedo__title {
    color: #fff;
  }

  .wedo__text {
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .wedo__text {
    max-width: 400px;
  }

  .wedo__text {
    line-height: 20px;
  }
}
@media (max-width: 578px) {
  .wedo {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 422px) {
  .wedo__title {
    font-size: 26px;
    line-height: 32px;
  }
}
.officially {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--bg);
}
.officially__title {
  margin: 0;
  font-family: "RobotoSlab-Thin";
  font-weight: 100;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: var(--bodi2);
}
.officially__content {
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #434253;
}
.officially__images {
  width: 70%;
  display: flex;
  justify-content: center;
}
.officially__list {
  display: flex;
  flex-direction: column;
}
.officially__li {
  max-width: 400px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}
.officially__li-img {
  margin-right: 30px;
}
.officially__link {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: var(--bodi3);
}
.officially__how {
  padding-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #434253;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.officially__how-item {
  display: flex;
}
.officially__how-number {
  font-family: "RobotoSlab-Thin";
  font-style: normal;
  font-weight: 100;
  font-size: 130px;
  line-height: 140px;
  text-align: center;
  text-transform: uppercase;
  color: var(--bodi2);
  padding: 0 72px;
}
.officially__how-text {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: var(--bodi3);
  max-width: 400px;
}
.officially__bottom {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.officially__bottom-item {
  max-width: 600px;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: var(--bodi2);
  margin: 0;
}
.officially__bottom-buttons {
  display: flex;
  flex-direction: column;
}
.officially__button:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 998px) {
  .officially {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .officially__title {
    font-size: 50px;
    line-height: 50px;
  }

  .officially__link {
    font-size: 18px;
  }

  .officially__how-text {
    font-size: 18px;
  }

  .officially__bottom-item {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .officially__title {
    font-size: 45px;
    line-height: 45px;
  }

  .officially__content {
    flex-direction: column;
  }

  .officially__list {
    padding-top: 60px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .officially__li {
    width: 48%;
  }

  .officially__how {
    flex-direction: column;
  }

  .officially__how-number {
    width: 190px;
  }

  .officially__bottom-item {
    font-size: 16px;
    line-height: 20px;
    max-width: 324px;
  }

  .officially__link {
    font-size: 18px;
  }

  .officially__how-text {
    font-size: 18px;
  }

  .officially__how-number {
    font-size: 100px;
    line-height: 110px;
  }
}
@media (max-width: 692px) {
  .officially__title {
    font-size: 35px;
    line-height: 35px;
  }
}
@media (max-width: 648px) {
  .officially__list {
    flex-direction: column;
  }

  .officially__li {
    width: 100%;
    padding-bottom: 20px;
  }

  .officially__how-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .officially__link {
    font-size: 16px;
    line-height: 20px;
  }

  .officially__how-text {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 578px) {
  .officially {
    padding-top: 80px;
    padding-bottom: 160px;
  }

  .officially__bottom {
    flex-direction: column;
  }

  .officially__bottom-item {
    max-width: 100%;
    color: #fff;
  }

  .officially__bottom-buttons {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-top: 40px;
  }
}
@media (max-width: 510px) {
  .officially__title {
    font-size: 35px;
    line-height: 37px;
  }

  .officially__button {
    width: 160px;
  }
}
@media (max-width: 422px) {
  .officially__title {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 360px) {
  .officially__button {
    width: 140px;
  }
}
.send {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--gradiend);
}
.send__title {
  margin: 0;
  font-family: "RobotoSlab-Thin";
  font-weight: 100px;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}
.send__email, .send__tel, .send__name, .send__comment {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  background-color: transparent;
  height: 40px;
  margin-bottom: 10px;
  color: #FFFFFF;
  padding: 0 10px 0 0;
  border: none;
  border-bottom: 1px solid #605E75;
  outline: none;
  width: 100%;
}
.send__email, .send__tel, .send__comment {
  margin-top: 10px;
}
.send__button-group {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.send__checkbox {
  display: flex;
  align-items: center;
}
.send__checkbox-text {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin: 0;
  max-width: 368px;
  margin-left: 16px;
}

form {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

label {
  position: relative;
}

input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  margin: 0.2em 0.5em;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.75em;
  transition: all 0.5s;
  border: 1px solid #f20000;
}

input[type=checkbox]:checked + label::before {
  content: "✓";
  border: 1px solid #fff;
  color: #2F2D3C;
}

.file-upload {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.file-upload .file-select {
  display: block;
  color: #34495e;
  cursor: pointer;
  height: 60px;
  line-height: 40px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(237.24deg, #7FC278 -82.09%, #6CA678 -40.01%, #77C584 12.14%, #AAEEA0 79.41%);
  border: 1px dashed #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload .file-select .file-select-button {
  padding: 0 10px;
  display: inline-block;
  height: 80px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  display: flex;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #2F2D3C;
}

.file-upload .file-select:hover {
  border-color: #34495e;
  transition: all 0.2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  color: #FFF;
  transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select {
  transition: all 0.2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  color: #FFF;
  transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload2 {
  padding-top: 20px;
  display: block;
  text-align: center;
}

.file-upload2 .file-select {
  display: block;
  color: #34495e;
  cursor: pointer;
  height: 60px;
  line-height: 40px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(237.24deg, #7FC278 -82.09%, #6CA678 -40.01%, #77C584 12.14%, #AAEEA0 79.41%);
  border: 1px dashed #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload2 .file-select .file-select-button {
  padding: 0 10px;
  display: inline-block;
  height: 80px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload2 .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  display: flex;
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #2F2D3C;
}

.file-upload2 .file-select:hover {
  border-color: #34495e;
  transition: all 0.2s ease-in-out;
}

.file-upload2 .file-select:hover .file-select-button {
  color: #FFF;
  transition: all 0.2s ease-in-out;
}

.file-upload2.active .file-select {
  transition: all 0.2s ease-in-out;
}

.file-upload2.active .file-select .file-select-button {
  color: #FFF;
  transition: all 0.2s ease-in-out;
}

.file-upload2 .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.file-upload2 .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload2 .file-select.file-select-disabled:hover {
  cursor: default;
  display: block;
  border: 2px solid #dce4ec;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFF;
  overflow: hidden;
  position: relative;
}

.file-upload2 .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload2 .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

@media (max-width: 998px) {
  .send {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .send__title {
    font-size: 50px;
    line-height: 50px;
  }

  .send__email, .send__tel, .send__name, .send__comment {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .send__title {
    font-size: 45px;
    line-height: 45px;
  }

  .send__email, .send__tel, .send__comment {
    margin-top: 10px;
  }

  form {
    padding-top: 40px;
  }

  .file-upload {
    padding-top: 20px;
  }
}
@media (max-width: 692px) {
  .send__title {
    font-size: 35px;
    line-height: 35px;
  }

  .send__email, .send__tel, .send__name, .send__comment {
    font-size: 18px;
  }
}
@media (max-width: 578px) {
  .send__checkbox-text {
    font-size: 18px;
    line-height: 18px;
  }

  .send {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .btn-platimir-send {
    width: 140px;
    margin-left: 2px;
  }

  .send__email, .send__tel, .send__name, .send__comment {
    height: 40px;
  }
}
@media (max-width: 510px) {
  .send__title {
    font-size: 30px;
    line-height: 30px;
  }
}
@media (max-width: 422px) {
  .send__title {
    font-size: 26px;
    line-height: 32px;
  }

  .file-upload .file-select input[type=file] {
    font-size: 16px;
    line-height: 22px;
  }

  .send__checkbox-text {
    font-size: 12px;
    line-height: 14px;
  }
}
.footer {
  background-color: var(--bg);
}
.footer__top {
  padding-top: 114px;
  display: flex;
  justify-content: space-between;
}
.footer__left {
  display: flex;
  align-items: center;
}
.footer__logo {
  margin-right: 40px;
}
.footer__tg {
  display: flex;
  align-items: center;
}
.footer__tg-img {
  margin-right: 12px;
}
.footer__tg-text {
  font-family: "EtelkaPro-Bold";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: var(--bodi2);
  transition: color 0.3s ease-in-out;
}
.footer__tg-text:hover {
  color: #65CD7B;
}
.footer__bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.footer__bottom-item p {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: var(--bodi2);
  max-width: 400px;
  margin: 0;
}
.footer__bottom-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer__tg-text-p {
  font-family: "EtelkaPro-Bold";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: var(--bodi2);
  padding-bottom: 20px;
  transition: color 0.3s ease-in-out;
  text-align: right;
}
.footer__tg-text-p:hover {
  color: #65CD7B;
}
.footer__copyright {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  color: var(--bodi1);
  padding-bottom: 50px;
}
.footer__webdev {
  display: flex;
  flex-direction: column;
}
.footer__webdev a {
  font-family: "EtelkaPro-Light";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: var(--bodi1);
  padding-bottom: 12px;
  transition: color 0.3s ease-in-out;
}
.footer__webdev a:hover {
  color: #65CD7B;
}

@media (max-width: 768px) {
  .footer__logo {
    margin-right: 0px;
  }

  .footer__left {
    flex-direction: column;
  }
}
@media (max-width: 696px) {
  .footer__bottom-item p {
    font-size: 20px;
    max-width: 280px;
  }
}
@media (max-width: 578px) {
  .footer__top {
    padding-top: 114px;
  }

  .footer__bottom-item p {
    font-size: 16px;
    max-width: 224px;
  }
}
@media (max-width: 524px) {
  .footer__tg {
    padding-top: 10px;
  }

  .footer__top {
    flex-direction: column;
  }

  .footer__left {
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .footer__bottom {
    flex-direction: column;
  }

  .footer__bottom-item p {
    max-width: 100%;
  }

  .footer__bottom-right {
    padding-top: 40px;
    width: 100%;
    align-items: flex-start;
    position: relative;
  }

  .footer__webdev {
    position: absolute;
    top: 44px;
    right: 0;
  }

  .footer__bottom {
    padding-bottom: 30px;
  }
}
@media (max-width: 400px) {
  .footer__tg-text-p {
    max-width: 100px;
  }
}
@media (max-width: 422px) {
  .footer__top {
    padding-top: 80px;
  }

  .footer__tg-text {
    font-size: 12px;
    line-height: 16px;
  }

  .footer__tg-img {
    margin-left: 5px;
  }

  .footer__tg {
    flex-direction: row-reverse;
  }

  .footer__logo {
    margin-right: 0px;
  }

  .footer__logo-img {
    width: 124px;
  }

  .footer__tg-img {
    margin-right: 0px;
  }
}
.header__404 {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fon {
  display: none;
}

.content-404 {
  padding-top: 50px;
  background-color: var(--bg);
}

.block-404 {
  padding-top: 0px;
  background-image: url("../img/men.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
}

.block-404-content {
  max-width: 400px;
  margin-left: auto;
}

.block-404-number {
  position: relative;
  font-family: "RobotoSlab-Thin";
  font-weight: 100;
  font-size: 240px;
  line-height: 260px;
  color: #65CD7B;
}

.block-404-text1, .block-404-text2 {
  font-family: "EtelkaPro-Light";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #CBCADA;
  margin: 0;
}

.block-404-text2 {
  color: #605E75;
  padding-top: 20px;
}

.button404 {
  margin-top: 40px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-img {
  position: absolute;
  top: 64px;
  right: 23px;
}

@media (max-width: 768px) {
  .block-404-number {
    font-size: 184px;
    line-height: 196px;
  }

  .block-404-text1, .block-404-text2 {
    font-size: 16px;
    line-height: 22px;
  }

  .block-404-content {
    max-width: 324px;
  }

  .content-404 {
    padding-top: 0px;
  }

  .button404 {
    margin-top: 40px;
  }

  .block-404 {
    padding-top: 0px;
  }
}
@media (max-width: 576px) {
  .block-404-text1, .block-404-text2 {
    padding-top: 10px;
  }

  .block-404-number {
    font-size: 100px;
    line-height: 100px;
    text-align: center;
    max-width: 210px;
    margin: 0 auto;
  }

  .error-img {
    top: 30px;
    right: -10px;
  }

  .block-404-content {
    max-width: 100%;
    margin-left: 0;
    margin: 0 auto;
  }

  .block-404-number {
    padding-top: 20px;
    text-align: center;
  }

  .fon {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
    max-width: 300px;
    margin: 0 auto;
  }

  .block-404 {
    background-image: none;
  }

  .block-404-text1, .block-404-text2 {
    font-size: 14px;
    line-height: 20px;
  }

  .button404 {
    margin-top: 40px;
  }
}
.graph-modal__content--more {
  background: #2F2D3C;
  border-radius: 2px;
  padding: 80px 100px;
}

.title-more {
  font-family: "RobotoSlab-Thin";
  font-weight: 100;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #605E75;
  display: block;
  padding-bottom: 30px;
}

.content-more p {
  font-family: "EtelkaPro-Light";
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #CBCADA;
}

.content-more {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.more-btn {
  background-color: #65CD7B;
}

.graph-modal__close {
  right: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .content-more {
    max-height: 400px;
  }

  .graph-modal__content--more {
    padding: 60px 40px;
  }

  .title-more {
    font-size: 36px;
    line-height: 42px;
  }

  .content-more p {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 576px) {
  .graph-modal__content--more {
    padding: 40px 20px;
  }

  .title-more {
    font-size: 20px;
    line-height: 26px;
  }

  .content-more p {
    font-size: 12px;
    line-height: 16px;
  }
}
.graph-modal__container--lead {
  max-width: 600px;
  background: var(--gradiend);
}

.graph-modal__container--lead .send {
  padding-top: 0px;
  padding-bottom: 0px;
  background: transparent;
}

.graph-modal__container--lead .send__title {
  font-size: 35px;
  line-height: 35px;
}

.graph-modal__container--lead .send__form {
  padding-top: 20px;
}

@media (max-width: 680px) {
  .file-upload2 .file-select {
    height: 50px;
  }

  .graph-modal__container--lead .send__title {
    font-size: 30px;
    line-height: 35px;
  }

  .graph-modal__container--lead .send {
    padding-top: 10px;
    padding-bottom: 0px;
    background: transparent;
  }

  .graph-modal__container--lead .send__checkbox-text {
    font-size: 14px;
    line-height: 18px;
  }

  .graph-modal__container--lead .send__email {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }

  .graph-modal__container--lead .send__tel {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }

  .graph-modal__container--lead .send__name {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }

  .graph-modal__container--lead .send__comment {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }

  .send__button-group {
    padding-top: 20px;
  }
}
@media (max-width: 510px) {
  .graph-modal__container--lead {
    padding: 0;
  }
}
@media (max-width: 400px) {
  .graph-modal__container--lead .send__title {
    font-size: 25px;
    line-height: 30px;
  }

  .graph-modal__container--lead .file-upload .file-select .file-select-button {
    font-size: 20px;
    line-height: 30px;
  }

  .graph-modal__container--lead .send__checkbox-text {
    margin-left: 0px;
  }

  .graph-modal__container--lead .send__checkbox-text {
    font-size: 12px;
    line-height: 14px;
  }

  .btn-platimir-send {
    width: 110px;
    margin-left: 2px;
  }

  .footer__tg-text-p {
    text-align: left;
  }

  .graph-modal__container--lead .send__email {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }

  .graph-modal__container--lead .send__tel {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }

  .graph-modal__container--lead .send__name {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }

  .graph-modal__container--lead .send__comment {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }

  .graph-modal__container--lead .file-upload2 .file-select {
    height: 30px;
  }

  .graph-modal__container--lead .file-upload2 {
    padding-top: 10px;
  }

  .graph-modal__container--lead .file-upload2 .file-select .file-select-button {
    font-size: 18px;
  }

  .graph-modal__container--lead .send__button-group {
    padding-top: 10px;
  }
}
.graph-modal__container--lead, .graph-modal__content--more {
  overflow: hidden;
}

.graph-modal__content-sps {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title-sps {
  font-size: 50px;
  line-height: 60px;
  padding-top: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.more-btn-sps {
  background-color: rgba(0, 0, 0, 0.3215686275);
}

.graph-modal__container-sps {
  max-width: 800px;
}

.content-more-sps {
  overflow: hidden;
}

.content-more-sps p {
  text-align: center;
  margin: 0;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.graph-modal__container-sps {
  padding: 40px;
}

@media (max-width: 576px) {
  .graph-modal__container-sps {
    padding: 20px;
  }

  .title-sps {
    font-size: 30px;
    line-height: 37px;
  }
}