.header {
  background-color: var(--bg);
		&__top {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
		}

		&__left {
      display: flex;
      align-items: center;
		}

		&__logo {
      margin-right: 40px;
		}

		&__logo-img {
		}

		&__tg {
      display: flex;
      align-items: center;
		}

		&__tg-img {
      margin-right: 12px;
		}

		&__tg-text {
      font-family: 'EtelkaPro-Bold';
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-decoration-line: underline;
      color: var(--bodi2);
      transition: color .3s ease-in-out;
		}
    &__tg-text:hover {
      color:#65CD7B;
    }

		&__right {
		}

		&__hero {
		}

		&__title {
      padding-top: 80px;
      font-family: 'RobotoSlab-Thin';
      font-weight: 100;
      font-size: 90px;
      line-height: 90px;
      text-align: center;
      text-transform: uppercase;
      color: var(--bodi2);
      margin: 0;
		}
    &__green {
      color: #65CD7B;
    }
		&__subtitle {
      font-family: 'EtelkaPro-Light';
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--bodi3);
      max-width: 800px;
      margin: 0 auto;
      padding-top: 40px;
		}

		&__hero-img {
      padding-top: 40px;
		}
    &__hero-wrapper {
      position: relative;
    }
    &__hero-img-gradient {
      position: absolute;
      background: linear-gradient(180deg, rgba(47, 45, 60, 0) 0%, #2F2D3C 100%);
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 200px;
    }
}

@media (max-width: 1240px) {
  .header__title {
    font-size: 80px;
    line-height: 80px;
}
}
@media (max-width: 998px) {
  .header__title {
    font-size: 70px;
    line-height: 70px;
}
}

@media (max-width: 956px) {
  .header__left {
      flex-direction: column;
  }
  .header__logo {
      padding-bottom: 20px;
  }
  .header__right {
    display: flex;
    flex-direction: column;
  }
  .btn-dark--header {
    margin-bottom: 20px;
  }
}


@media (max-width: 768px) {
  .header__title {
    font-size: 65px;
    line-height: 65px;
  }
}
@media (max-width: 694px) {
  .header__title {
      font-size: 60px;
      line-height: 60px;
  }
  .header__subtitle {
    font-size: 18px;
  }
}

@media (max-width: 614px) {
  .header__title {
      font-size: 52px;
      line-height: 50px;
  }
  .header__subtitle {
    font-size: 18px;
  }
}

@media (max-width: 578px) {
  .header__top {
    flex-direction: column;
  }
  .header__left {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .header__logo {
    padding-bottom: 0px;
  }
  .header__right {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
  }
  .header__title {
    padding-top: 100px;
  }
  .header__subtitle {
    padding-bottom: 80px;
  }
}

@media (max-width: 510px) {
  .header__title {
      font-size: 48px;
      line-height: 48px;
  }
}

@media (max-width: 486px) {
  .header__title {
      font-size: 45px;
      line-height: 45px;
  }
  .header__subtitle {
  font-size: 15px;
}
}

@media (max-width: 482px) {
  .header__tg-text {
    font-size: 12px;
    line-height: 16px;
  }
  .header__tg-img {
    margin-left: 5px;
  }
  .header__tg {
    flex-direction: row-reverse;
  }
  .header__logo {
    margin-right: 0px;
  }
  .header__logo-img {
    width: 124px;
  }
  .header__tg-img {
    margin-right: 0px;
  }
}

@media (max-width: 440px) {
  .header__title {
      font-size: 42px;
      line-height: 42px;
  }
}

@media (max-width: 422px) {
  .header__title {
    font-size: 40px;
    line-height: 40px;
  }
  .btn-platimir {
    height: 40px;
    font-size: 10px;
    line-height: 12px;
  }
}
@media (max-width: 400px) {
  .header__title {
      font-size: 38px;
      line-height: 38px;
  }
}

@media (max-width: 380px) {
  .header__title {
      font-size: 36px;
      line-height: 36px;
  }
}

@media (max-width: 360px) {
  .header__title {
    font-size: 34px;
    line-height: 34px;
  }
  .header__tg-img {
    width: 20px;
  }
}


@media (max-width: 360px) {
  .header__title {
    font-size: 33px;
    line-height: 33px;
  }
 .header__subtitle {
  font-size: 14px;
  line-height: 17px;
 }
}

@media (max-width: 332px) {
  .header__title {
    font-size: 32px;
    line-height: 32px;
  }
}
