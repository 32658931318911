.graph-modal__content--more {
  background: #2F2D3C;
  border-radius: 2px;
  padding: 80px 100px;
}

.title-more {
  font-family: 'RobotoSlab-Thin';
  font-weight: 100;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #605E75;
  display: block;
  padding-bottom: 30px;
}


.content-more p {
  font-family: 'EtelkaPro-Light';
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  color: #CBCADA;
}

.content-more {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.more-btn {
  background-color: #65CD7B;
}
.graph-modal__close {
    right: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1024px) {
.content-more {
  max-height: 400px;
}
.graph-modal__content--more {
  padding: 60px 40px;
}
.title-more {
  font-size: 36px;
  line-height: 42px;
}
.content-more p {
  font-size: 16px;
  line-height: 22px;
}

}
@media (max-width: 576px) {
  .graph-modal__content--more {
  padding: 40px 20px;
}
.title-more {
  font-size: 20px;
  line-height: 26px;
}
.content-more p {
  font-size: 12px;
  line-height: 16px;
}
}


.graph-modal__container--lead {
max-width: 600px;
 background: var(--gradiend);
}
.graph-modal__container--lead .send {
  padding-top:0px;
  padding-bottom: 0px;
  background: transparent;
}
.graph-modal__container--lead .send__title {
  font-size: 35px;
  line-height: 35px;
}
.graph-modal__container--lead .send__form {
  padding-top: 20px;
}


@media (max-width: 680px) {
  .file-upload2 .file-select {
    height: 50px;
  }
.graph-modal__container--lead  .send__title {
      font-size: 30px;
      line-height: 35px;
  }
  .graph-modal__container--lead .send {
    padding-top:10px;
    padding-bottom: 0px;
    background: transparent;
  }
  .graph-modal__container--lead .send__checkbox-text {
    font-size: 14px;
    line-height: 18px;
  }
  .graph-modal__container--lead .send__email {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }
  .graph-modal__container--lead .send__tel {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
  }
   .graph-modal__container--lead .send__name {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
   }
  .graph-modal__container--lead .send__comment {
    font-size: 22px;
    margin-bottom: 10px;
    height: 40px;
    }
    .send__button-group {
    padding-top: 20px;
    }
}

@media (max-width: 510px) {
 .graph-modal__container--lead {
   padding: 0;
 }
}

@media (max-width: 400px) {
  .graph-modal__container--lead .send__title {
    font-size: 25px;
    line-height: 30px;
  }


  .graph-modal__container--lead .file-upload .file-select .file-select-button {
    font-size: 20px;
    line-height: 30px;
  }
  .graph-modal__container--lead .send__checkbox-text {
    margin-left: 0px;
  }
  .graph-modal__container--lead .send__checkbox-text {
    font-size: 12px;
    line-height: 14px;
  }
  .btn-platimir-send {
    width: 110px;
    margin-left: 2px;
  }
  .footer__tg-text-p {
    text-align: left;
  }

  .graph-modal__container--lead .send__email {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }
  .graph-modal__container--lead .send__tel {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
  }
   .graph-modal__container--lead .send__name {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
   }
  .graph-modal__container--lead .send__comment {
    font-size: 18px;
    margin-bottom: 10px;
    height: 30px;
    }
  .graph-modal__container--lead .file-upload2 .file-select {
    height: 30px;
    }
  .graph-modal__container--lead .file-upload2 {
    padding-top: 10px;
  }
  .graph-modal__container--lead .file-upload2 .file-select .file-select-button {
    font-size: 18px;
  }
  .graph-modal__container--lead .send__button-group {
    padding-top: 10px;
  }
}

.graph-modal__container--lead, .graph-modal__content--more{
     overflow: hidden;
}


.graph-modal__content-sps {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title-sps {
  font-size: 50px;
  line-height: 60px;
  padding-top: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.more-btn-sps {
  background-color: #00000052;
}

.graph-modal__container-sps {
  max-width: 800px;
}

.content-more-sps {
  overflow: hidden;
}

.content-more-sps p {
  text-align: center;
  margin: 0;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.graph-modal__container-sps {
  padding: 40px;
}

@media (max-width: 576px) {
  .graph-modal__container-sps {
    padding: 20px;
  }

.title-sps {
  font-size: 30px;
  line-height: 37px;
}
}

