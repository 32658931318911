.officially {
  padding-top: 120px;
  padding-bottom: 120px;
  background: var(--bg);
		&__title {
      margin: 0;
      font-family: 'RobotoSlab-Thin';
      font-weight: 100;
      font-size: 60px;
      line-height: 60px;
      text-align: center;
      color: var(--bodi2);
		}

		&__content {
      padding-top: 50px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #434253;
		}

		&__images {
      width: 70%;
      display: flex;
      justify-content: center;
		}
    &__img {
    }
		&__list {
      display: flex;
      flex-direction: column;
		}

		&__li {
      max-width: 400px;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
		}

		&__li-img {
      margin-right: 30px;
		}

		&__link {
      font-family: 'EtelkaPro-Light';
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      color: var(--bodi3);
		}

		&__how {
      padding-top: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid #434253;
      display: flex;
      justify-content: space-between;
      align-items: start;
		}

		&__how-item {
      display: flex;
		}

		&__how-number {
      font-family: 'RobotoSlab-Thin';
      font-style: normal;
      font-weight: 100;
      font-size: 130px;
      line-height: 140px;
      text-align: center;
      text-transform: uppercase;
      color: var(--bodi2);
      padding: 0 72px;
		}

		&__how-text {
      font-family: 'EtelkaPro-Light';
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      color: var(--bodi3);
      max-width: 400px;
		}

		&__bottom {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: start;
		}

		&__bottom-item {
      max-width: 600px;
      font-family: 'EtelkaPro-Light';
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      color: var(--bodi2);
      margin: 0;
		}

		&__bottom-buttons {
      display: flex;
      flex-direction: column;
		}

		&__button:not(:last-child){
      margin-bottom: 20px;
		}
}

@media (max-width: 998px) {
  .officially {
      padding-top: 80px;
      padding-bottom: 80px;
  }
  .officially__title {
      font-size: 50px;
      line-height: 50px;
  }
  .officially__link {
    font-size: 18px;
  }
  .officially__how-text {
    font-size: 18px;
  }
  .officially__bottom-item {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .officially__title {
    font-size: 45px;
    line-height: 45px;
  }
  .officially__content {
    flex-direction: column;
  }
  .officially__list {
    padding-top: 60px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .officially__li {
    width: 48%;
  }
  .officially__how {
    flex-direction: column;
  }
  .officially__how-number {
    width: 190px;
  }
  .officially__bottom-item {
    font-size: 16px;
    line-height: 20px;
    max-width: 324px
  }
  .officially__link {
    font-size: 18px;
  }
  .officially__how-text {
      font-size: 18px;
  }
  .officially__how-number {
    font-size: 100px;
    line-height: 110px;
  }
}
@media (max-width: 692px) {
  .officially__title {
      font-size: 35px;
      line-height: 35px;
  }
}
@media (max-width: 648px) {
  .officially__list {
    flex-direction: column;
  }
  .officially__li {
    width: 100%;
    padding-bottom: 20px;
  }

  .officially__how-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .officially__link {
  font-size: 16px;
  line-height: 20px;
}
.officially__how-text {
  font-size: 16px;
    line-height: 20px;
}
}

@media (max-width: 578px) {
  .officially{
    padding-top: 80px;
    padding-bottom: 160px;
  }
  .officially__bottom {
    flex-direction: column;
  }
  .officially__bottom-item {
     max-width: 100%;
     color: #fff;
  }
  .officially__bottom-buttons {
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      padding-top: 40px;
  }

}

@media (max-width: 510px) {
.officially__title {
    font-size: 35px;
    line-height: 37px;
}
 .officially__button {
    width: 160px;
  }
}
@media (max-width: 422px) {
  .officially__title {
      font-size: 26px;
      line-height: 32px;
  }
}

@media (max-width: 360px) {
 .officially__button {
    width: 140px;
  }
}
